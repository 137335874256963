
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import customers from "@/core/data/customers";
import { INotes } from "@/core/data/notes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import router from "@/router";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    // ExportCustomerModal,
    // AddCustomerModal,
  },
  setup() {
    const store = useStore();
    const checkedViewnotes = ref([]);
    const showTable = ref(false);
    const route = useRoute();
    const groupId = ref<any>("");
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        name: "Number",
        key: "mobileno",
        sortable: true,
      },
      {
        name: "Message",
        key: "message",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdAt",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<INotes>>([]);
    const initCustomers = ref<Array<INotes>>([]);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Contact Note", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      // console.log('tableData',tableData.value);
      getItem();
      groupId.value = route.params.groupId;
    });

    const getItem = () => {
      var groupId = route.params.groupId;
      var id = route.params.id;
      var request = {
        url: "/contact/note",
        data: {
          group_id: groupId,
          contact_id: id,
        },
      };
      store.dispatch(Actions.POST, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );

          showTable.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const deleteFewNotes = () => {
      checkedViewnotes.value.forEach((item) => {
        deleteNotes(item);
      });
      checkedViewnotes.value.length = 0;
    };

    const deleteNotes = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          // if (result.isConfirmed) {
          var request = {
            url: `note/${id}`,
          };
          store.dispatch(Actions.DELETE, request).then((data) => {
            if (data) {
              showTable.value = false;
              Swal.fire(
                "Deleted!",
                "Contact Note has been deleted.",
                "success"
              );
              getItem();
            }
          });
          // }
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<INotes> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    return {
      tableData,
      tableHeader,
      deleteNotes,
      search,
      searchItems,
      checkedViewnotes,
      deleteFewNotes,
      dateTime,
      showTable,
      getItem,
      groupId,
    };
  },
});
